<template>
  <footer>
      <span><span class="far fa-copyright"></span> Santiago Maresca Thomé | May 2022</span>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
footer {
    background: #353535;
    padding: 20px 23px;
    text-align: start;
}
footer span {
    color: #fff;
}
</style>